<template>
  <component
    :is="asyncMenuMobile"
    :animationTimeInMs="300"
    :menu="menus"
    aria-label="Мобильное меню"
    @close="closeMenuMobileHandler"
    @open="toggleMenuMobileHandler"
  >
    <template #backIcon="{ back }">
      <button class="button-navigation" @click="back">
        <i class="icon-main-prev"></i>
      </button>
    </template>

    <template #closeIcon="{ close }">
      <button class="button-navigation" @click="close"><i class="icon-main-close"></i></button>
    </template>

    <template #headerLabel="{ item }">
      <h4>
        {{ item?.label }}
      </h4>
    </template>

    <template #item="{ item }">
      <span class="link-item link-item--rounded p-menu-mobile__page-link">
        <span v-if="item.icon.before" class="p-menu-mobile__page-icon-before">
          <i :class="`icon-${item.icon.before}`"></i>
        </span>

        <a
          v-if="item.attributes.url && item.children.length <= 0"
          :href="item.attributes.url"
          :rel="item.attributes.outside ? 'nofollow' : undefined"
          :target="item.attributes.outside ? '_blank' : undefined"
          :title="item.attributes.title"
          class="p-menu-mobile__page-text"
          @click.prevent
        >
          {{ item.name }}
        </a>

        <span v-else :title="item.attributes.title" class="p-menu-mobile__page-text">
          {{ item.name }}
        </span>

        <span v-if="item.children.length" class="p-menu-mobile__page-icon-after">
          <i class="icon-main-next" />
        </span>
      </span>
    </template>

    <template #pageStackTitle="{ item }">
      <h5 v-if="item.attributes.url">
        <a :href="item.attributes.url" class="link-item link-item--rounded">
          {{ item.name }}
        </a>
      </h5>
    </template>

    <template #loading>
      <Loader theme="small" />
    </template>
  </component>
</template>

<script lang="ts" setup>
// Vue
import { ref } from 'vue';

// Components
import Loader from '@ice-products-ui/vue-library/Loader';

// Types
import type { IMenuMobile, IMenuMobileMenuItem } from '@ice-products-ui/vue-library/MenuMobile';
import type { IData } from '~/typings/types/data.types';

// Composables
import useGetData from '~/composables/api/useGetData';
import useForms, { EFormNames } from '~/composables/molecules/useForms';
import useMenu from '~/composables/organisms/useMenu';

const asyncMenuMobile = defineAsyncComponent(
  () => import('@ice-products-ui/vue-library/MenuMobile'),
);

const payload = useState<IData<Component>>('data');

const { baseApiUrl } = useGetData();
const { requireForm, formList } = useForms();
const { toggleMenuMobileHandler, closeMenuMobileHandler } = useMenu();

const menus = ref<Array<IMenuMobileMenuItem>>([
  {
    label: 'Все услуги',
    icon: 'icon-service-domestic',
    key: 'services',
    items: [],
    loading: false,
    command: async () => {
      const menuDesktopData = menus.value[0];
      menuDesktopData.loading = true;
      menuDesktopData.items = await fetchServicesMenu();
      menuDesktopData.loading = false;
    },
  },
  {
    label: 'Заявка',
    key: 'request',
    icon: 'icon-main-add',
    items: [],
    loading: false,
    command: (event) => {
      event.preventDefault();
      event.close();
      requireForm(formList[EFormNames.FORM_CALLMASTER]);
    },
  },
  {
    label: 'Меню',
    key: 'about',
    icon: `icon-service-menu`,
    items: [],
    loading: false,
    command: async () => {
      const menuDesktopData = menus.value[2];
      menuDesktopData.loading = true;
      menuDesktopData.items = await fetchServicesMain();
      menuDesktopData.loading = false;
    },
  },
]);

const fetchServicesMain = async (): Promise<Array<IMenuMobile>> => {
  return await $fetch(`menu/main?contexts=${payload.value.contexts}`, {
    baseURL: baseApiUrl,
    method: 'GET',
  });
};

const fetchServicesMenu = async (): Promise<Array<IMenuMobile>> => {
  return await $fetch(`menu/services?contexts=${payload.value.contexts}`, {
    baseURL: baseApiUrl,
    method: 'GET',
  });
};
</script>
